import React, { useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

import { get } from "lodash";

import "./ProductTable.css";
import { navigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { fetchTable, setTable } from "../product/productSlice";
import format from "number-format.js";
import { NUMBER_FORMAT } from "../utils/constants";

const buildColumnDefs = (metrics) => {
  const metricsColumns = metrics.map((m) => ({
    headerName: m.name,
    field: m.metricCode,
    valueFormatter: (params) => format(m.format || NUMBER_FORMAT, params.value),
  }));

  const columnDefs = [
    {
      headerName: "Station",
      //field: "Station",
      field: "StationName",
      cellClass: "selected-column",
      pinned: "left",
    },
    {
      headerName: "Creative",
      field: "CreativeName",
    },
    ...metricsColumns,
  ];

  return columnDefs;
};

const ProductTable = () => {
  const client = useSelector((state) => state.user.client);
  const weeks = useSelector((state) => state.user.weeks);
  const product = useSelector((state) => state.user.product);
  const selectedMarket = useSelector((state) => state.product.selectedMarket);
  const selectedWeekIndex = useSelector(
    (state) => state.user.selectedWeekIndex
  );
  const metrics = useSelector((state) =>
    get(state, "product.tableMetrics", [])
  );

  const tableData = useSelector((state) => state.product.tableData);

  const columnDefs = buildColumnDefs(metrics);
  const dispatch = useDispatch();
  useEffect(() => {
    const selectedWeek =
      selectedWeekIndex >= 0 ? weeks[selectedWeekIndex] : null;
    if (client && client.clientCode && product && product.productCode) {

      dispatch(
        fetchTable(
          client.clientCode,
          product.productCode,
          selectedWeek,
          selectedMarket.marketCode
        )
      );
    } else {
      dispatch(setTable({}));
    }
  }, [client, product, weeks, selectedMarket, selectedWeekIndex]);

  const gridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: true,
      minWidth: 170,
    },
    pagination: true,
    paginationPageSize: 10,
  };

  return (
    <>
      <div className="ag-theme-balham product-table">
        <AgGridReact
          onRowClicked={(row) => {
            navigate(`/table/${row.data.Station}/${row.data.Creative}`);
          }}
          columnDefs={columnDefs}
          rowData={tableData}
          getRowHeight={() => 50}
          suppressCellSelection
          gridOptions={gridOptions}
          immutableData={true}
          getRowNodeId={(data) => `${data.Station}_${data.CreativeName}`}
        />
      </div>
    </>
  );
};

export default ProductTable;
