import { combineReducers } from "@reduxjs/toolkit";

import userSlice from "./user/userSlice";
import productSlice from "./product/productSlice";
import adminSlice from "./admin/adminSlice";

const rootReducer = combineReducers({
  user: userSlice,
  product: productSlice,
  admin: adminSlice
});

export default rootReducer;
