import React from "react";

import "./Switch.css";

const Switch = ({ title, description }) => (
  <div className="switch">
    <div className="switch__text-box">
      <div className="switch__title">{title}</div>
      <div className="switch__description">{description}</div>
    </div>
    <label className="switch__control">
      <input type="checkbox" className="switch__input"/>
      <span className="switch__slider" />
    </label>
  </div>
);

export default Switch;
