import React from "react";

import "./DashboardProducts.css";
import {useDispatch} from "react-redux";
import {setSelectedProductIndex} from "../user/userSlice";
import {navigate} from "@reach/router";

const DashboardProducts = ({ products, dataByProducts }) => {
  const dispatch = useDispatch();
  const handleProductSelect = (index) => {
    dispatch(setSelectedProductIndex(index));
    navigate('/');
  }

  return (
    <table className="dashboard-products">
      <thead className="dashboard-products__head">
      <tr>
        <th className="dashboard-products__product-title">Product</th>
        <th className="dashboard-products__spend-title">Spend</th>
        <th className="dashboard-products__title-col">Revenue</th>
        <th className="dashboard-products__title-col">CPV</th>
      </tr>
      </thead>
      <tbody>
      {products.map((p, index) => (
        <tr className="dashboard-products__row" key={index} onClick={() => handleProductSelect(index)}>
          <td className="dashboard-products__product-col">{p.name}</td>
          <td className="dashboard-products__spend-col">{(dataByProducts[p.productCode] || {}).spend || 0.0}</td>
          <td className="dashboard-products__revenue-col">
            {(dataByProducts[p.productCode] || {}).revenue || 0.0}
          </td>
          <td className="dashboard-products__cpv-col">{(dataByProducts[p.productCode] || {}).cpv || 0.0}</td>
        </tr>
      ))}
      </tbody>
    </table>
  );
}

export default DashboardProducts;
