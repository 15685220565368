export const ROOT_URL = process.env.REACT_APP_API_URL;

export const NUMBER_FORMAT = "#,##0.";
export const CURRENCY_FORMAT = "$#,##0.";
export const PERCENTAGE_FORMAT = "#,##0.%";

export const DAYS_OF_WEEK = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
export const DAYS_OF_WEEK_LONG_TO_SHORT = {
         Monday: "Mon",
         Tuesday: "Tue",
         Wednesday: "Wed",
         Thursday: "Thu",
         Friday: "Fri",
         Saturday: "Sat",
         Sunday: "Sun",
       };

export const STATUS_CODE_OK = 200;
export const STATUS_CODE_UNAUTHORIZED = 401;


export const weeksTitles = ["Current Week", "Last Week", "2 Weeks Ago", "3 Weeks Ago"];
export const weeksColors = ["#FF827A", "#D165F2", "#FFC938", "#F973C1"];
