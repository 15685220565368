import React, { useState } from "react";
import cn from "classnames";

import "./Toggle.css";

const TileSvg = ({ active }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path
      fill={active ? "#FFF" : "#A0B4C8"}
      fillRule="evenodd"
      d="M8.333 11c.369 0 .667.298.667.667v4.666a.667.667 0 0 1-.667.667H3.667A.667.667 0 0 1 3 16.333v-4.666c0-.369.298-.667.667-.667h4.666zm8 0c.369 0 .667.298.667.667v4.666a.667.667 0 0 1-.667.667h-4.666a.667.667 0 0 1-.667-.667v-4.666c0-.369.298-.667.667-.667h4.666zm-8-8c.369 0 .667.298.667.667v4.666A.667.667 0 0 1 8.333 9H3.667A.667.667 0 0 1 3 8.333V3.667C3 3.298 3.298 3 3.667 3h4.666zm8 0c.369 0 .667.298.667.667v4.666a.667.667 0 0 1-.667.667h-4.666A.667.667 0 0 1 11 8.333V3.667c0-.369.298-.667.667-.667h4.666z"
    />
  </svg>
);

const Toggle = ({ handleToggle, className, location }) => {
  const [active, setActive] = useState(false);

  const doToggle = val => {
    setActive(val);
    handleToggle(val);
  };

  return (
    <div className={`${className} toggle`}>
      <div
        className={cn("toggle__chart", { "toggle__item--active": !active })}
        onClick={() => doToggle(false)}
      >
        <TileSvg active={!active} />
      </div>
      <div
        className={cn("toggle__table", { "toggle__item--active": active })}
        onClick={() => doToggle(true)}
      >
        <img src="/images/view-list.svg" alt="Table" />
      </div>
    </div>
  );
};

export default Toggle;
