export const dayPartsColorMap = {
  ON: "#ff827a",
  AM: "#d165f2",
  DA: "#ffc938",
  WK: "#f973c1",
  EM: "#5c99ff",
  EF: "#d165f2",
  PA: "#5ad3ff",
  PR: "#fba2e5",
  LF: "#6963ff",
  BN: "#f973c1",
  PM: "#5c99ff",
  RS: "#5ad3ff",
  RT: "#fba2e5",
  WN: "#6963ff",
  "": "#ff827a",
};

export const dayPartsTitle = {
  AM: "AM Drive",
  BN: "Bonus",
  DA: "Daytime",
  EF: "Early Fringe",
  EM: "Early Morning",
  LF: "Late Fringe",
  ON: "Overnight",
  PA: "Prime Access",
  PM: "Prime Mirror",
  PR: "Prime Time",
  RS: "ROS",
  RT: "Rotator",
  WK: "Weekend",
  WN: "Weekend Prime",
  "": "Unknown",
};

export const spotLengthsColorMap = {
  15: "#ffc938",
  30: "#d165f2",
  60: "#ff827a",
  90: "#5ad3ff",
  120: "#fba2e5",
};
