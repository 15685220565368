import React from "react";
import "./DataSelector.css";
import Dropdown from "./Dropdown";
import { useDispatch } from "react-redux";
import {selectMarket} from "../product/productSlice";

const DataSelector = ({ markets, selectedMarket, classes = '' }) => {
  const dispatch = useDispatch();

  const handleSelect = market => {
    dispatch(selectMarket(market));
  };

  return (
    <div className={`${classes} data-selector`}>
      <Dropdown
        options={markets}
        selectedOption={selectedMarket}
        className="select-market"
        renderOption={m => m && m.name}
        handleSelect={handleSelect}
      />
    </div>
  );
};

export default DataSelector;
