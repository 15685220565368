import React, {useEffect} from "react";

import DashboardTitle from "./DashboardTitle";
import DashboardChart from "./DashboardChart";

import "./Dashboard.css";
import DashboardProducts from "./DashboardProducts";
import {useDispatch, useSelector} from "react-redux";
import {fetchDashboard, setDashboard} from "../user/userSlice";

const Dashboard = () => {
  const client = useSelector(state => state.user.client);
  const products = useSelector(state => state.user.products);
  const weeks = useSelector(state => state.user.weeks);
  const selectedWeekIndex = useSelector(state => state.user.selectedWeekIndex);
  const dashboard = useSelector(state => state.user.dashboard);

  const dispatch = useDispatch();

  useEffect(() => {
    const selectedWeek = selectedWeekIndex >=0 ? weeks[selectedWeekIndex] : null;
    if (client && client.clientCode) {
      dispatch(fetchDashboard(client.clientCode, selectedWeek));
    } else {
      dispatch(setDashboard({}));
    }
  }, [client, weeks, selectedWeekIndex]);

  return (
    <div className="dashboard">
      <DashboardTitle clientName={client.name} />
      <DashboardChart className="dashboard__dashboard-chart" dashboard={dashboard} />
      <div className="dashboard__products">Products</div>
      <DashboardProducts products={products} dataByProducts={dashboard.byProduct || {}}/>
    </div>
  );
};

export default Dashboard;
