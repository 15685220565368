import React, { useEffect } from "react";

import "./Admin.css";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  doUserUpdate,
  fetchAccounts,
  fetchClients,
  fetchUser,
} from "./adminSlice";
import { Link } from "@reach/router";
import UserForm from "./UserForm";

const selectUser = createSelector(
  (state) => state.admin.users || [],
  (_, userId) => userId,
  (users, userId) => users.find((user) => user.id === userId) || {}
);

const UserEdit = ({ userId }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.admin.users);
  const user = useSelector((state) => selectUser(state, userId));
  const clients = useSelector((state) => state.admin.clients);
  const accounts = useSelector((state) => state.admin.accounts);

  useEffect(() => {
    dispatch(fetchAccounts());
    dispatch(fetchClients());
  }, []);

  useEffect(() => {
    if (users == null) {
      dispatch(fetchUser(userId));
    }
  }, [userId]);

  const handleSubmit = (user) => {
    dispatch(
      doUserUpdate({
        id: userId,
        ...user,
      })
    );
  };

  return (
    <>
      <div className="admin__header">
        <h1 className="admin-header__title">Edit User</h1>
        <Link to="/admin/users" className="app-button admin-header__app-button">
          Return to Users
        </Link>
      </div>
      <div className="admin__content">
        <UserForm
          isCreate={false}
          user={user}
          accounts={accounts}
          clients={clients}
          submitHandler={handleSubmit}
        />
      </div>
    </>
  );
};

export default UserEdit;
