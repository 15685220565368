import React, { useEffect } from "react";

import { AgGridReact } from "ag-grid-react";
import { useDispatch, useSelector } from "react-redux";
import {doUserDelete, fetchUsers} from "./adminSlice";
import { Link, navigate } from "@reach/router";
import { dayPartsColorMap, dayPartsTitle } from "../utils/ColorMaps";

const columnDefs = [
  {
    headerName: "Name",
    field: "lastName",
    cellRenderer: (params) =>
      `${params.data.lastName} ${params.data.firstName}`,
  },

  {
    headerName: "Email",
    field: "email",
  },
  {
    headerName: "Role",
    field: "role",
  },
  {
    headerName: "Account",
    field: "account",
    cellRenderer: (params) => (params.value || {}).name,
  },
  {
    headerName: "Client",
    field: "client",
    cellRenderer: (params) => (params.value || {}).name,
  },
  {
    headerName: "",
    field: "delete",
    minWidth: 50,
    sortable: false,
    resizable: false,
    flex: 1,
    cellStyle: { 'align-items': "center", "justify-content": "center" },
    cellRenderer: "actionCellRenderer",
  },
];

const actionCellRenderer = ({ value }) => (
  <img src="/images/delete.svg" alt="Delete" />
);

const frameworkComponents = {
  actionCellRenderer,
};

const Users = () => {
  const dispatch = useDispatch();

  const users = useSelector((state) => state.admin.users);

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
    // params.api.setDomLayout("autoHeight");
  };

  const gridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: true,
      minWidth: 170,
    },
    pagination: true,
    paginationPageSize: 10,
  };

  const handlerUserDelete = (userId) => {
    dispatch(doUserDelete(userId));
  }

  return (
    <>
      <div className="admin__header">
        <h1 className="admin-header__title">Users</h1>
        <Link
          to="/admin/users/add"
          className="app-button admin-header__app-button"
        >
          Add User
        </Link>
      </div>
      <div className="admin__content">
        <div className="ag-theme-balham admin-table">
          <AgGridReact
            onCellClicked={(event) => {
              if(event.colDef.field === "delete") {
                handlerUserDelete(event.data.id);
              } else {
                navigate(`/admin/users/${event.data.id}`);
              }
            }}
            columnDefs={columnDefs}
            rowData={users}
            getRowHeight={() => 50}
            onGridReady={onGridReady}
            // suppressCellSelection
            gridOptions={gridOptions}
            immutableData={true}
            getRowNodeId={(data) => data.id}
            frameworkComponents={frameworkComponents}
          />
        </div>
      </div>
    </>
  );
};

export default Users;
