import React from "react";
import { get } from "lodash";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { connect } from "react-redux";
import { spotLengthsColorMap } from "../utils/ColorMaps";
import format from "number-format.js";
import {NUMBER_FORMAT, PERCENTAGE_FORMAT} from "../utils/constants";

const getOptions = (spotLengthChart,  selectedMetric) => {
  const keys = Object.keys(spotLengthChart) || [];
  const categories = keys.map(k => `${k}sec`);

  const data = keys.map(k => ({
      y: get(spotLengthChart, `${k}.${selectedMetric.metricCode}`),
      color: spotLengthsColorMap[k]
  }));

  return {
    chart: {
      type: "bar",
      height: 340,
      legend: null
    },
    title: {
      text: null
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories,
      labels: {
        style: {
          fontSize: "16px"
        }
      }
    },
    yAxis: {
      title: null,
      labels: {
        formatter: function () {
          return format(selectedMetric.format || NUMBER_FORMAT, this.value)
        },
        style: {
          fontSize: "12px"
        }
      }
    },
    tooltip: {
      formatter: function () {
        return `<b>${this.x}</b><br/>` +
          `<span style="color:${this.point.color}">●</span> ` +
          this.point.series.name + ': ' +
          format(selectedMetric.format || NUMBER_FORMAT, this.y)
          ;
      }
    },
    series: [
      {
        name: selectedMetric.name,
        showInLegend: false,
        pointWidth: 20,
        data
      }
    ]
  };
};

const SpotLengthChart = ({ spotLengthChart,  selectedMetric}) => {
  const options = getOptions(spotLengthChart,  selectedMetric);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

const mapStateToProps = state => {
  return {
    spotLengthChart: state.product.spotLengthChart,
    selectedMetric: state.product.selectedMetric,
  };
};

export default connect(mapStateToProps, null)(SpotLengthChart);
