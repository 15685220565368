import React, { useEffect } from "react";
import { Link } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";

import { doUserCreate, fetchAccounts, fetchClients } from "./adminSlice";
import UserForm from "./UserForm";

import "./Admin.css";

const UserAdd = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.admin.users);
  const clients = useSelector((state) => state.admin.clients);
  const accounts = useSelector((state) => state.admin.accounts);

  useEffect(() => {
    dispatch(fetchAccounts());
    dispatch(fetchClients());
  }, []);

  const handleSubmit = (user) => {
    dispatch(doUserCreate(user));
  };

  return (
    <>
      <div className="admin__header">
        <h1 className="admin-header__title">Add a new User</h1>
        <Link to="/admin/users" className="app-button admin-header__app-button">
          Return to Users
        </Link>
      </div>
      <div className="admin__content">
        <UserForm
          isCreate={true}
          user={{}}
          accounts={accounts}
          clients={clients}
          submitHandler={handleSubmit}
        />
      </div>
    </>
  );
};

export default UserAdd;
