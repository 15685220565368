import React from "react";
import {get, sumBy, pick} from "lodash";
import "./DaypartsChart.css";
import Highcharts from "highcharts";
import PieChart from "highcharts-react-official";
import {connect} from "react-redux";
import {dayPartsColorMap} from "../utils/ColorMaps";
import format from "number-format.js";
import {NUMBER_FORMAT, PERCENTAGE_FORMAT} from "../utils/constants";

const getData = (daypartChart, dayparts, selectedMetric) => {
  const data = dayparts.map((d) => ({
    name: d.name,
    y: get(daypartChart, `${d.code}.${selectedMetric.metricCode}`, 0),
    color: dayPartsColorMap[d.code],
  })).filter(d => d.y > 0);

  const otherKeys = Object.keys(daypartChart).filter(
    (f) => !dayparts.find((d) => d.code === f)
  );

  if (otherKeys.length) {
    const otherObjects = Object.values(pick(daypartChart, otherKeys));
    const otherValue = sumBy(otherObjects, selectedMetric.metricCode);
    if (otherValue > 0) {
      data.push({
        name: "Other",
        y: otherValue,
        color: dayPartsColorMap[""],
      });
    }
  }

  return data;
};

const getOptions = (data, selectedMetric) => {
  return {
    chart: {
      type: "pie",
      height: 320,
    },
    title: {
      text: "",
      align: "center",
      verticalAlign: "middle",
    },
    credits: {
      enabled: false
    },
    tooltip: {
      formatter: function () {
        return '<b>' + this.key + '</b><br/>' +
          `<span style="color:${this.point.color}">●</span> ` +
          this.point.series.name + ': ' +
          format(selectedMetric.format || NUMBER_FORMAT, this.y) + ' - ' +
          format(PERCENTAGE_FORMAT, this.percentage)
          ;
      }
    },

    series: [
      {
        name: selectedMetric.name,
        dataLabels: {
          enabled: false,
        },
        innerSize: "80%",
        data,
      },
    ],
  };
};

const DaypartsChart = ({daypartChart, dayparts, selectedMetric}) => {
  const data = getData(daypartChart, dayparts, selectedMetric);
  const total = sumBy(data, "y");
  const options = getOptions(data, selectedMetric);

  return (
    <div className="dayparts-chart">
      <div className="dayparts-chart__list">
        {data.map((d, index) => (
          <div className="daypart-row" key={index}>
            <div
              className="daypart-row__point"
              style={{backgroundColor: d.color}}
            />
            <div className="daypart-row__title">{d.name}</div>
            <div className="daypart-row__percent">
              {format("0.0%", total === 0 ? 0 : (d.y / total) * 100.0)}
            </div>
          </div>
        ))}
      </div>
      <div className="dayparts-chart__chart">
        <PieChart highcharts={Highcharts} options={options} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    daypartChart: state.product.daypartChart,
    dayparts: state.product.dayparts,
    selectedMetric: state.product.selectedMetric,
  };
};

export default connect(mapStateToProps, null)(DaypartsChart);
