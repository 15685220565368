import React from "react";

import "./Login.css";
import {useFormik} from "formik";
import * as Yup from "yup";
import {NotificationContainer} from "react-notifications";
import {useSelector} from "react-redux";

const Login = ({handleLogin}) => {
  const loginError = useSelector((state) => state.user.loginError);

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {email: "", password: ""},
    validationSchema: Yup.object({
      email: Yup.string().required("Required"),
      password: Yup.string().required("Required")
    }),
    onSubmit: values => {
      formik.setSubmitting(true);
      handleLogin(values);
      formik.setSubmitting(false);
    }
  });

  return (
    <div className="login-page">
      {/*<img className="login-logo" src="/images/chief-media-logo-svg-white-final.svg" alt="Logo"/>*/}

      <form className="login" onSubmit={formik.handleSubmit}>
        <div className="login__title">Login</div>
        <input
          type="text"
          className="login__form-control"
          placeholder="Email"
          name="email"
          {...formik.getFieldProps("email")}
        />
        <input
          type="password"
          className="login__form-control"
          placeholder="Password"
          name="password"
          {...formik.getFieldProps("password")}
        />
        {
          loginError &&
          (<div className="login__error">{loginError}</div>)
        }
        <button
          className="login__button"
          disabled={formik.isSubmitting}
          type="submit"
        >
          Login
        </button>
      </form>

    </div>
  );
};

export default Login;
