import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {connect} from "react-redux";
import {get} from "lodash";
import format from "number-format.js";
import {NUMBER_FORMAT} from "../utils/constants";

const getOptions = (creativeChart, selectedMetric) => {
  const keys = Object.keys(creativeChart);
  const categories = keys.map(k => get(creativeChart, `${k}.CreativeName`));
  const data = keys.map(k => get(creativeChart, `${k}.${selectedMetric.metricCode}`));

  return {
    chart: {
      type: "bar",
      height: 340,
      legend: null,
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories,
      labels: {
        style: {
          fontSize: "16px",
        },
      },
    },
    yAxis: {
      title: null,
      labels: {
        // format: "${value:,.0f}",
        formatter: function () {
          return format(selectedMetric.format || NUMBER_FORMAT, this.value)
        },
        style: {
          fontSize: "12px",
        },
      },
    },
    tooltip: {
      formatter: function () {
        return `<b>${this.x}</b><br/>` +
          `<span style="color:${this.point.color}">●</span> ` +
          this.point.series.name + ': ' +
          format(selectedMetric.format || NUMBER_FORMAT, this.y)
          ;
      }

    },
    series: [
      {
        name: selectedMetric.name,
        showInLegend: false,
        pointWidth: 20,
        color: "#ffc938",
        data,
      },
    ],
  };
};

const CreativeChart = ({ creativeChart, selectedMetric }) => {
  const options = getOptions(creativeChart, selectedMetric);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

const mapStateToProps = (state) => {
  return {
    creativeChart: state.product.creativeChart,
    selectedMetric: state.product.selectedMetric
  };
};

export default connect(mapStateToProps, null)(CreativeChart);
