import React from "react";
import "./ProductChart.css";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ChartTab from "./ChartTab";
import { connect, useDispatch } from "react-redux";
import { get } from "lodash";
import format from "number-format.js";
import {
  weeksTitles,
  DAYS_OF_WEEK,
  DAYS_OF_WEEK_LONG_TO_SHORT,
  NUMBER_FORMAT,
  weeksColors,
} from "../utils/constants";
import { selectMetric } from "../product/productSlice";

const getOptions = (chartData, selectedMetric, selectedMedia) => {
  const series = chartData
    .map((k, index) => {
      if (!k[selectedMedia]) {
        return undefined;
      }
      const values = k[selectedMedia];
      const valuePerDayOfWeek = {};
      values.forEach((v) => {
        const weekDay = DAYS_OF_WEEK_LONG_TO_SHORT[v.BroadcastDay];
        const dayValue = v[selectedMetric.metricCode];

        if (valuePerDayOfWeek[weekDay]) {
          valuePerDayOfWeek[weekDay] += dayValue;
        } else {
          valuePerDayOfWeek[weekDay] = dayValue;
        }
      });

      return {
        name: weeksTitles[index],
        color: weeksColors[index % weeksColors.length],
        data: DAYS_OF_WEEK.map((d) => valuePerDayOfWeek[d] || 0),
        marker: { symbol: "circle" },
      };
    })
    .filter((s) => !!s);

  return {
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: DAYS_OF_WEEK,
      gridLineWidth: 1,
    },
    yAxis: {
      title: null,
      labels: {
        formatter: function () {
          return format(selectedMetric.format || NUMBER_FORMAT, this.value);
        },
      },
    },
    legend: {
      enabled: true,
      useHTML: true,
      symbolWidth: 0,
      labelFormatter: function () {
        const checked =
          '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><rect width="20" height="20" fill="' +
          this.color +
          '" rx="5"/><path fill="#FFF" d="M5.707 10.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l7-7a1 1 0 1 0-1.414-1.414L8 12.586l-2.293-2.293z"/></g></svg>';

        const unchecked =
          '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><rect width="20" height="20" fill="' +
          this.color +
          '" rx="5"/><rect width="16" height="16" x="2" y="2" fill="#FFF" rx="3"/></g></svg>';
        return (
          '<div style="display: flex; justify-content: center; align-items: center;"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">\n' +
          (this.visible ? checked : unchecked) +
          '<div style="margin-left: 5px;">' +
          this.name +
          "</div>" +
          "</div>"
        );
      },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      formatter: function () {
        return this.points.reduce((s, point) =>
            (s + '<br/>' +
              `<span style="color:${point.color}">●</span> ` +
              point.series.name + ': ' +
              format(selectedMetric.format || NUMBER_FORMAT, point.y))
        , '<b>' + this.x + '</b>');
      },
    },
    series,
  };
};

const ProductChart = ({
  metrics,
  selectedMetric,
  selectedMedia,
  currentWeek,
  previousWeek,
  mainChart,
  classes,
}) => {
  const dispatch = useDispatch();

  const options = getOptions(mainChart, selectedMetric, selectedMedia);

  const handleSelect = (metric) => {
    dispatch(selectMetric(metric));
  };

  return (
    <div className={`${classes} product-chart`}>
      <div className="product-chart__title">
        {metrics.map((m, index) => {
          const metricCode = m.metricCode;
          const metricFormat = m.format || NUMBER_FORMAT;

          const current = get(currentWeek, metricCode, 0);
          const previous = get(previousWeek, metricCode, 0);

          if (m.isArray) {
          } else {
            const percent =
              previous === 0
                ? undefined
                : Math.abs(((current - previous) * 100.0) / previous);

            return (
              <ChartTab
                key={index}
                name={m.name}
                amount={format(metricFormat, current)}
                percent={percent}
                selected={m === selectedMetric}
                handleSelect={() => handleSelect(m)}
                up={
                  current == previous || previous == 0
                    ? null
                    : current > previous
                }
              />
            );
          }
        })}
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    metrics: get(state, "product.chartMetrics", []),
    selectedMetric: state.product.selectedMetric,
    selectedMedia: state.product.selectedMedia,
    currentWeek: state.product.currentWeek,
    previousWeek: state.product.previousWeek,
    mainChart: state.product.mainChart,
  };
};

export default connect(mapStateToProps, null)(ProductChart);
