import React, { useEffect } from "react";
import cn from "classnames";
import "./DaypartTable.css";

import { get } from "lodash";

import { AgGridReact } from "ag-grid-react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDaypartTable,
  setDaypartTable,
} from "../product/productSlice";
import format from "number-format.js";
import { NUMBER_FORMAT } from "../utils/constants";
import moment from "moment";
import { dayPartsTitle } from "../utils/ColorMaps";
import { navigate } from "@reach/router";

const buildColumnDefs = (metrics) => {
  const metricsColumns = metrics.map((m) => ({
    headerName: m.name,
    field: m.metricCode,
    valueFormatter: (params) => format(m.format || NUMBER_FORMAT, params.value),
  }));

  return [
    {
      headerName: "Cal Date",
      field: "DateTime",
      valueFormatter: (params) => moment(params.value).format("MM/DD/YYYY"),
    },
    {
      headerName: "Time",
      field: "DateTime",
      valueFormatter: (params) => moment(params.value).format("hh:mm:ss A"),
    },
    {
      headerName: "Program",
      field: "ProgramName",
    },
    ...metricsColumns,
  ];
};

const DaypartTable = ({ station, creative, daypart }) => {
  const dispatch = useDispatch();

  const client = useSelector((state) => state.user.client);
  const weeks = useSelector((state) => state.user.weeks);
  const product = useSelector((state) => state.user.product);
  const selectedMarket = useSelector((state) => state.product.selectedMarket);
  const selectedWeekIndex = useSelector(
    (state) => state.user.selectedWeekIndex
  );
  const metrics = useSelector((state) =>
    get(state, "product.tableMetrics", [])
  );

  const daypartTableData = useSelector(
    (state) => state.product.daypartTableData
  );

  useEffect(() => {
    const selectedWeek =
      selectedWeekIndex >= 0 ? weeks[selectedWeekIndex] : null;
    if (client && client.clientCode && product && product.productCode) {
      dispatch(
        fetchDaypartTable(
          client.clientCode,
          product.productCode,
          selectedWeek,
          station,
          creative,
          daypart,
          selectedMarket.marketCode
        )
      );
    } else {
      dispatch(setDaypartTable({}));
    }
  }, [
    client,
    product,
    weeks,
    selectedMarket,
    selectedWeekIndex,
    station,
    creative,
    daypart,
  ]);

  const selectDaypart = (dp) => {
    navigate(`/table/${station}/${creative}/${dp}`);
  };

  const columnDefs = buildColumnDefs(metrics);

  const gridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: true,
      minWidth: 170,
    },
    pagination: true,
    paginationPageSize: 10,
  };

  return (
    <>
      <div className="daypart-selector">
        {/*<div className="daypart-selector__option" onClick={() => selectDaypart("")}>All</div>*/}
        {Object.keys(dayPartsTitle)
          .filter((k) => !!k)
          .map((d) => (
            <div
              className={cn("daypart-selector__option", {
                "daypart-selector__option--active": d === daypart,
              })}
              onClick={() => selectDaypart(d)}
            >
              {dayPartsTitle[d]}
            </div>
          ))}
      </div>
      <div className="ag-theme-balham daypart-table">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={daypartTableData}
          getRowHeight={() => 50}
          suppressCellSelection
          gridOptions={gridOptions}
          immutableData={true}
          getRowNodeId={(data) => data.DateTime}
        />
      </div>
    </>
  );
};

export default DaypartTable;
