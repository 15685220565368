import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import { Scrollbars } from "react-custom-scrollbars";
import moment from "moment";

import "./Dropdown.css";
import {findIndex} from "lodash";

const Dropdown = ({
  options,
  selectedOption,
  renderOption,
  className,
  handleSelect
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const timeoutRef = useRef(null);

  const scrollBarsHeight = Math.min(400, options.length * 40);
  const scrollbars = useRef(null);

  const stopHiding = () => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
  };

  const hidding = () => {
    stopHiding();

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      setShowDropdown(false);
    }, 500);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const selectedIndex = findIndex(options, w => w === selectedOption);

  useEffect(() => {
    if (showDropdown && options.length > 0) {
      const scrollHeight = scrollbars.current.getScrollHeight();
      const scrollPos = Math.max(
        0,
        scrollHeight * (1 - (1 / options.length) * (selectedIndex + 5))
      );
      scrollbars.current.scrollTop(scrollPos);
    }
  }, [showDropdown]);

  const defaultRenderOption = option => option;
  const currentRenderOption = renderOption || defaultRenderOption;

  return (
    <div
      className={cn(className, "c-dropdown", {
        "c-dropdown--active": showDropdown
      })}
      onClick={toggleDropdown}
      onMouseLeave={hidding}
      onMouseEnter={stopHiding}
    >
      {options && options.length ? currentRenderOption(selectedOption) : ""}
      <img
        className="c-dropdown__arrow"
        src="/images/caret-down-solid--black.svg"
        alt=""
      />
      {showDropdown && (
        <div className="week-range-dropdown">
          <Scrollbars style={{ height: scrollBarsHeight }} ref={scrollbars}>
            {options.map((o, index) => {
              return (
                <div
                  key={index}
                  className={cn("week-range-dropdown-item", {
                    "week-range-dropdown-item--active": o === selectedOption
                  })}
                  onClick={() => handleSelect(o, index)}
                >
                  <div className="week-range-dropdown-item__range">
                    {currentRenderOption(o, index)}
                  </div>
                </div>
              );
            })}
          </Scrollbars>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
