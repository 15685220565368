import React from "react";

import "./ChartPanel.css";

const ChartPanel = ({ title, chart }) => (
  <div className="chart-panel">
    <div className="chart-panel__title">
        {title}
        {/* <img src="/images/expand.svg" alt="Expand" /> */}
    </div>
    {chart}
  </div>
);

export default ChartPanel;
