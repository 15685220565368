import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { connect } from "react-redux";
import { get } from "lodash";
import format from "number-format.js";
import { NUMBER_FORMAT, weeksColors, weeksTitles } from "../utils/constants";

const getOptions = (weeksChart, selectedMetric) => {
  const categories = weeksTitles;
  const data = weeksChart.map((k, index) => ({
    y: get(k, selectedMetric.metricCode),
    color: weeksColors[index % weeksColors.length],
  }));

  return {
    chart: {
      type: "bar",
      height: 340,
      legend: null,
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories,
      labels: {
        style: {
          fontSize: "16px",
        },
      },
    },
    yAxis: {
      title: null,
      labels: {
        // format: "${value:,.0f}",
        formatter: function () {
          return format(selectedMetric.format || NUMBER_FORMAT, this.value);
        },
        style: {
          fontSize: "12px",
        },
      },
    },
    tooltip: {
      formatter: function () {
        return `<b>${this.x}</b><br/>` +
          `<span style="color:${this.point.color}">●</span> ` +
          this.point.series.name + ': ' +
          format(selectedMetric.format || NUMBER_FORMAT, this.y)
          ;
      }

    },
    series: [
      {
        name: selectedMetric.name,
        showInLegend: false,
        pointWidth: 20,
        color: "#ffc938",
        data,
      },
    ],
  };
};

const WeeksChart = ({ weeksChart, selectedMetric }) => {
  const options = getOptions(weeksChart, selectedMetric);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

const mapStateToProps = (state) => {
  return {
    weeksChart: state.product.weeksChart,
    selectedMetric: state.product.selectedMetric,
  };
};

export default connect(mapStateToProps, null)(WeeksChart);
