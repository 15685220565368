import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addChartMetric,
  addTableMetric,
  delChartMetric,
  delTableMetric,
  doSaveProductMetrics,
  fetchClientProducts,
  fetchClients,
  fetchMetrics,
  selectClient,
  selectProduct, updateTableMetricOrder,
} from "./adminSlice";

const Metrics = () => {
  const dispatch = useDispatch();
  const metrics = useSelector((state) => state.admin.metrics);
  const clients = useSelector((state) => state.admin.clients);
  const selectedClient = useSelector((state) => state.admin.selectedClient);
  const clientProducts = useSelector((state) => state.admin.clientProducts);
  const selectedProduct = useSelector((state) => state.admin.selectedProduct);

  useEffect(() => {
    dispatch(fetchMetrics());
    dispatch(fetchClients());
  }, []);

  useEffect(() => {
    if (selectedClient.clientCode) {
      dispatch(fetchClientProducts(selectedClient.clientCode));
    }
  }, [selectedClient]);

  const handleClientSelect = (event) => {
    const client = clients.find((c) => c.clientCode === event.target.value);
    dispatch(selectClient(client || {}));
  };

  const handleProductSelect = (event) => {
    const product = clientProducts.find(
      (p) => p.productCode === event.target.value
    );
    dispatch(selectProduct(product || {}));
  };

  const handleTableMetricChange = (metric, isPresent) => {
    if (isPresent) {
      dispatch(delTableMetric(metric));
    } else {
      dispatch(addTableMetric({
        ...metric,
        order: 0
      }));
    }
  }

  const handleChartMetricChange = (metric, isPresent) => {
    if (isPresent) {
      dispatch(delChartMetric(metric));
    } else {
      dispatch(addChartMetric(metric));
    }
  }

  const handleTableMetricOrderUpdate = (metric, order) => {
    dispatch(updateTableMetricOrder({metric, order}));
  };

  const handleSave = () => {
    if (selectedProduct.productCode) {
      dispatch(doSaveProductMetrics(selectedProduct));
    }
  };

  return (
    <>
      <div className="form-row admin__selectors-container">
        <div className="form-group admin__form-group form-group--no-grow">
          <label htmlFor="lastName" className="form-label">
            Client
          </label>
          <select
            className="form-control"
            onChange={handleClientSelect}
            value={selectedClient.clientCode}
          >
            {clients.map((c) => (
              <option value={c.clientCode}>{c.name}</option>
            ))}
          </select>
        </div>
        <div className="form-group admin__form-group form-group--no-grow admin__product-selector">
          <label htmlFor="lastName" className="form-label">
            Product
          </label>
          <select
            className="form-control "
            onChange={handleProductSelect}
            value={selectedProduct.productCode}
          >
            {clientProducts.map((p) => (
              <option value={p.productCode}>{p.name}</option>
            ))}
          </select>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <td>Table</td>
            <td>Grid (Chart)</td>
            <td>Order</td>
            <td>Code</td>
            <td>Name</td>
          </tr>
        </thead>
        <tbody>
          {metrics.map((m) => {
            const tableMetrix = (selectedProduct.tableMetrics || []).find(
              (pm) => pm.metricCode === m.metricCode
            );

            const presentInChart = !!(selectedProduct.chartMetrics || []).find(
              (pm) => pm.metricCode === m.metricCode
            );

            return (
              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={!!tableMetrix}
                    onChange={() => handleTableMetricChange(m, !!tableMetrix)}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={presentInChart}
                    onChange={() => handleChartMetricChange(m, presentInChart)}
                  />
                </td>
                <td>
                  <input
                      type="number"
                      value={tableMetrix && tableMetrix.order}
                      disabled={!tableMetrix}
                      onChange={(event) => handleTableMetricOrderUpdate(m, event.target.valueAsNumber)}
                  />
                </td>
                <td>{m.metricCode}</td>
                <td>{m.name}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <button className="app-button admin__save-button" onClick={handleSave}>
        Save
      </button>
    </>
  );
};

export default Metrics;
