import React from "react";

import "./Admin.css";
import {Link} from "@reach/router";

const Admin = (props) => {

  return (
    <div className="admin">
      <div className="admin__sidebar">
        {/*<Link to="/admin/accounts">Accounts</Link>*/}
        <Link className="sidebar__link" to="/admin/users">Users</Link>
        <Link className="sidebar__link" to="/admin/metrics">Metrics</Link>
        <Link className="sidebar__link" to="/admin/weeks">Weeks</Link>
      </div>
      <div className="admin__main">
        {props.children}
      </div>
    </div>
  );
};

export default Admin;
