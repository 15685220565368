import React from "react";

import "./Message.css";

const Message = () => (
  <div className="message">
    <div className="message__title">AXE Body Spray</div>
    <div className="message__body">AXE Body Spray is on target to reach its weekly goal of *KPI here*</div>
    <div className="message__time">2 Hours ago</div>
  </div>
);

export default Message;
