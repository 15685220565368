import React from "react";
import "./Footer.css";

const Footer = () => (
  <div className="footer">
    <div>© Copyright. All Rights Reserved.</div>
    <div> Terms of Service | Privacy Policy</div>
  </div>
);

export default Footer;
