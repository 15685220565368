import React from "react";
import DataSelector from "./DataSelector";
import Toggle from "./Toggle";
import { navigate } from "@reach/router";
import {connect, useSelector} from "react-redux";

import "./TitleBar.css";
import ExportReport from "./ExportReport";
import { useDispatch } from "react-redux";
import {doCheckAuthentication} from "../user/userSlice";
import {fetchDaypartTable, fetchExcel, setDaypartTable} from "../product/productSlice";
const TitleBar = ({ markets, selectedMarket }) => {

  const dispatch = useDispatch();

  const client = useSelector((state) => state.user.client);
  const weeks = useSelector((state) => state.user.weeks);
  const product = useSelector((state) => state.user.product);
  const selectedWeekIndex = useSelector(
      (state) => state.user.selectedWeekIndex
  );

  const handleToggle = (val) => {
    if (val) {
      navigate("/table");
    } else {
      navigate("/");
    }
  };

  const handleExport = () => {
    const selectedWeek = selectedWeekIndex >= 0 ? weeks[selectedWeekIndex] : null;
    if (client && client.clientCode && product && product.productCode) {
      dispatch(
          fetchExcel(
              client.clientCode,
              product.productCode,
              selectedWeek,
              selectedMarket.marketCode
          )
      );
    }
  }

  return (
    <div className="title-bar">
      <DataSelector markets={markets} selectedMarket={selectedMarket} />
      <ExportReport onExport={handleExport}/>
      <Toggle handleToggle={handleToggle} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    markets: state.product.markets,
    selectedMarket: state.product.selectedMarket,
  };
};

export default connect(mapStateToProps, null)(TitleBar);
