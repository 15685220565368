import React, {useEffect, useState} from "react";
import {Router} from "@reach/router";
import {NotificationContainer} from 'react-notifications';
import Header from "./components/Header";
import Breadcrumbs from "./components/Breadcrumbs";
import Footer from "./components/Footer";
import ProductGrid from "./components/ProductGrid";
import ProductTable from "./components/ProductTable";
import ChannelTable from "./components/ChannelTable";
import DaypartTable from "./components/DaypartTable";
import TitleBar from "./components/TitleBar";

import "./App.css";
import 'react-notifications/lib/notifications.css';

import {connect, useDispatch, useSelector} from "react-redux";
import Dashboard from "./components/Dashboard";
import AccountSettings from "./components/AccountSettings";
import Notifications from "./components/Notifications";
import Login from "./components/Login";
import {doChangeClient, doCheckAuthentication, doLogin, fetchClients, setWeeks} from "./user/userSlice";
import Admin from "./admin/Admin";
import Users from "./admin/Users";
import Metrics from "./admin/Metrics";
import UserEdit from "./admin/UserEdit";
import UserAdd from "./admin/UserAdd";
import Weeks from "./admin/Weeks";

const App = () => {
  const [showNotifications, setShowNotifications] = useState(false);

  const selectedClientIndex = useSelector((state) => state.user.selectedClientIndex);
  const client = useSelector((state) => state.user.client);
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(doCheckAuthentication());
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(fetchClients());
    }
  }, [user]);

  useEffect(() => {
    if (client && client.clientCode) {
      dispatch(doChangeClient(client.clientCode));
    } else {
      dispatch(setWeeks([]));
    }
  }, [selectedClientIndex, client])

  const handleLogin = ({email, password}) => {
    dispatch(doLogin(email, password));
  };

  if (!user) {
    return <Login handleLogin={handleLogin}/>;
  }

  return (
    <div className="app">

      {showNotifications && (
        <Notifications hideNotifications={() => setShowNotifications(false)}/>
      )}
      <Header
        showNotifications={() => setShowNotifications(true)}
      />
      <Router primary={false}>
        <Breadcrumbs path="/" isTable={false}/>
        <Breadcrumbs path="/account-settings"/>
        <Breadcrumbs path="/table"/>
        <Breadcrumbs path="/table/:station"/>
        <Breadcrumbs path="/table/:station/:creative"/>
        <Breadcrumbs path="/table/:station/:creative/:daypart"/>
      </Router>
      <Router primary={false}>
        <TitleBar path="/"/>
        <TitleBar path="/table"/>
      </Router>

      <Router className="router" primary={false}>
        <ProductGrid path="/"/>
        <Dashboard path="/dashboard"/>
        <ProductTable path="/table"/>
        <ChannelTable path="/table/:station"/>
        <ChannelTable path="/table/:station/:creative"/>
        <DaypartTable path="/table/:station/:creative/:daypart"/>
        <AccountSettings path="/account-settings"/>
        <Admin path="/admin">
          {/*<Accounts path="/accounts"  />*/}
          <Users path="/users" default/>
          <UserAdd path="/users/add"/>
          <UserEdit path="/users/:userId"/>
          <Metrics path="/metrics"/>
          <Weeks path="/weeks"/>
        </Admin>
      </Router>
      <Footer/>
      <NotificationContainer/>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: !!state.user.user,
  };
};

export default connect(mapStateToProps, null)(App);
