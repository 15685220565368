import {createSlice} from "@reduxjs/toolkit";
import {
  createUser,
  deleteUser,
  getAccounts,
  getClients,
  getMetrics,
  getProducts,
  getUserById,
  getUsers,
  getWeeks,
  saveEnabledWeeks,
  saveProductMetrics,
  updateUser,
} from "../utils/api";
import {navigate} from "@reach/router";

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    accounts: [],
    clients: [],
    users: null,
    metrics: [],
    selectedClient: {},
    clientProducts: [],
    selectedProduct: {},
    allWeeks: [],
    enabledWeeks: [],
  },
  reducers: {
    setAccounts(state, action) {
      state.accounts = action.payload;
    },
    setUsers(state, action) {
      state.users = action.payload;
    },
    setUser(state, action) {
      if (state.users == null) {
        state.users = [action.payload];
      } else {
        state.users = state.users.map((user) => {
          if (user.id !== action.payload.id) {
            return user;
          }
          return action.payload;
        });
      }
    },
    setMetrics(state, action) {
      state.metrics = action.payload;
    },
    setClients(state, action) {
      state.clients = action.payload;
      if (state.clients.length > 0) {
        state.selectedClient = state.clients[0];
      } else {
        state.selectedClient = {};
      }
    },
    selectClient(state, action) {
      state.selectedClient = action.payload;
    },
    setClientProducts(state, action) {
      state.clientProducts = action.payload;
      if (state.clientProducts.length > 0) {
        state.selectedProduct = state.clientProducts[0];
      } else {
        state.selectedProduct = {};
      }
    },
    selectProduct(state, action) {
      state.selectedProduct = action.payload;
    },
    addTableMetric(state, action) {
      state.selectedProduct.tableMetrics = [
        ...state.selectedProduct.tableMetrics,
        action.payload,
      ];
    },
    updateTableMetricOrder(state, action) {
      state.selectedProduct.tableMetrics = state.selectedProduct.tableMetrics.map(t => {
        if (t.metricCode === action.payload.metric.metricCode) {
          return {
            ...t,
            order: action.payload.order
          }
        }

        return t;
      });
    },
    delTableMetric(state, action) {
      state.selectedProduct.tableMetrics = (
        state.selectedProduct.tableMetrics || []
      ).filter((item, index) => item.metricCode !== action.payload.metricCode);
    },
    addChartMetric(state, action) {
      state.selectedProduct.chartMetrics = [
        ...state.selectedProduct.chartMetrics,
        action.payload,
      ];
    },
    delChartMetric(state, action) {
      state.selectedProduct.chartMetrics = (
        state.selectedProduct.chartMetrics || []
      ).filter((item, index) => item.metricCode !== action.payload.metricCode);
    },
    setWeeks(state, action) {
      state.allWeeks = action.payload.all;
      state.enabledWeeks = action.payload.enabled;
    },
    addEnabledWeek(state, action) {
      state.enabledWeeks = [...state.enabledWeeks, action.payload];
    },
    delEnabledWeek(state, action) {
      state.enabledWeeks = (state.enabledWeeks || []).filter(
        (item) => item !== action.payload
      );
    },
  },
});

export default adminSlice.reducer;

export const {
  setAccounts,
  setUsers,
  setUser,
  setMetrics,
  setClients,
  setClientProducts,
  selectClient,
  selectProduct,
  addTableMetric,
  updateTableMetricOrder,
  delTableMetric,
  addChartMetric,
  delChartMetric,
  setWeeks,
  addEnabledWeek,
  delEnabledWeek,
} = adminSlice.actions;

export const fetchAccounts = () => {
  return async (dispatch) => {
    try {
      const accounts = await getAccounts();
      dispatch(setAccounts(accounts));
    } catch (error) {
      console.error("fetchAccounts error: ", error);
    }
  };
};

export const fetchUsers = () => {
  return async (dispatch) => {
    try {
      const users = await getUsers();
      dispatch(setUsers(users));
    } catch (error) {
      console.error("fetchUsers error: ", error);
    }
  };
};

export const fetchUser = (userId) => {
  return async (dispatch) => {
    try {
      const user = await getUserById(userId);

      dispatch(setUser(user));
    } catch (error) {
      console.error("fetchUsers error: ", error);
    }
  };
};

export const fetchMetrics = () => {
  return async (dispatch) => {
    try {
      const metrics = await getMetrics();
      dispatch(setMetrics(metrics));
    } catch (error) {
      console.error("fetchMetrics error: ", error);
    }
  };
};

export const fetchClients = () => {
  return async (dispatch) => {
    try {
      const clients = await getClients();
      dispatch(setClients(clients));
    } catch (error) {
      console.error("fetchClients error: ", error);
    }
  };
};

export const fetchClientProducts = (clientCode) => {
  return async (dispatch) => {
    try {
      const products = await getProducts(clientCode);

      dispatch(setClientProducts(products));
    } catch (error) {
      console.error("fetchClientProducts error: ", error);
    }
  };
};

export const fetchWeeks = (clientCode) => {
  return async (dispatch) => {
    try {
      const weeks = await getWeeks(clientCode);

      dispatch(setWeeks(weeks));
    } catch (error) {
      console.error("fetchClientProducts error: ", error);
    }
  };
};

export const doSaveProductMetrics = (product) => {
  return async (dispatch, getState) => {
    try {
      const selectedClient = getState().admin.selectedClient;
      if (
        selectedClient.clientCode &&
        product.productCode &&
        product.chartMetrics &&
        product.tableMetrics
      ) {
        await saveProductMetrics(selectedClient.clientCode, product);
      }
    } catch (error) {
      console.error("error: ", error);
    }
  };
};

export const doSaveEnabledWeeks = (clientCode, enabledWeeks) => {
  return async (dispatch) => {
    try {
      if (clientCode) {
        await saveEnabledWeeks(clientCode, enabledWeeks);
      }
    } catch (error) {
      console.error("error: ", error);
    }
  };
};

export const doUserCreate = (user) => {
  return async (dispatch) => {
    try {
      await createUser(user);
      await navigate("/admin/users");
    } catch (error) {
      console.error("error: ", error);
    }
  };
};

export const doUserUpdate = (user) => {
  return async (dispatch) => {
    try {
      await updateUser(user);
      await navigate("/admin/users");
    } catch (error) {
      console.error("error: ", error);
    }
  };
};

export const doUserDelete = (userId) => {
  return async (dispatch) => {
    try {
      await deleteUser(userId);
      dispatch(fetchUsers());
    } catch (error) {
      console.error("error: ", error);
    }
  };
};
