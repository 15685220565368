import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { get } from "lodash";
import {connect} from "react-redux";
import format from "number-format.js";
import {NUMBER_FORMAT} from "../utils/constants";

const getOptions = (stationChart, selectedMetric) => {
  var stationNameKey = "StationName";
  const categories = Object.keys(stationChart);
  //const data = categories.map(k => get(stationChart, `${k}.${selectedMetric.metricCode}`));
  const data = categories.map(k => get(stationChart, `${k}.${selectedMetric.metricCode}`), );
  //const stationNames = categories.map(k => get(stationChart, `${k}.${stationNameKey}`), );
  const stationNames = new Object();

  if(data.length > 0)
  {
    //debugger;
    Object.keys(stationChart).forEach(function(key) {
        var row = stationChart[key];
        stationNames[key] = row.StationName;
      });
  }



  return {
    chart: {
      type: "column",
      height: 340,
      legend: null
    },
    credits: {
      enabled: false
    },
    title: {
      text: null
    },
    xAxis: {
      categories
    },
    yAxis: {
      title: null,
      labels: {
        // format: "${value:,.0f}",
        formatter: function () {
          return format(selectedMetric.format || NUMBER_FORMAT, this.value)
        },
        style: {
          fontSize: "16px"
        }
      }
    },
    tooltip: {
      formatter: function () {
        return `<b>${stationNames[this.x]}</b><br/>` +
          `<span style="color:${this.point.color}">●</span> ` +
          this.point.series.name + ': ' +
          format(selectedMetric.format || NUMBER_FORMAT, this.y)
          ;
      }

    },
    series: [
      {
        name: selectedMetric.name,
        showInLegend: false,
        pointWidth: 20,
        color: "#d165f2",
        data
      }
    ]
  };
};

const StationChart = ({stationChart, selectedMetric}) => {
  const options = getOptions(stationChart, selectedMetric);
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

const mapStateToProps = state => {
  return {
    stationChart: state.product.stationChart,
    selectedMetric: state.product.selectedMetric
  };
};

export default connect(mapStateToProps, null)(StationChart);
