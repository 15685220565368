import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import { Scrollbars } from "react-custom-scrollbars";
import moment from "moment";
import { findIndex } from "lodash";

import "./WeekSelector.css";
import { useDispatch } from "react-redux";
import { setSelectedWeekIndex } from "../user/userSlice";

const WeekSelector = ({ weeks, selectedWeekIndex, className }) => {
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const timeoutRef = useRef(null);

  const scrollBarsHeight = Math.min(400, weeks.length * 40);
  const scrollbars = useRef(null);

  const stopHiding = () => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
  };

  const hidding = () => {
    stopHiding();

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      setShowDropdown(false);
    }, 500);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    if (showDropdown && weeks.length > 0) {
      const scrollHeight = scrollbars.current.getScrollHeight();
      const scrollPos = Math.max(
        0,
        scrollHeight * (1 - (1 / weeks.length) * (selectedWeekIndex + 5))
      );
      scrollbars.current.scrollTop(scrollPos);
    }
  }, [showDropdown]);

  const weekRange = (week) => (
    <>
      <div className="week-start">
        {moment(week.start).format("D MMM, YYYY")}
      </div>
      -<div className="week-end">{moment(week.end).format("D MMM, YYYY")}</div>
    </>
  );

  const weekNumberTitle = selectedWeekIndex >= 0 && `Week ${selectedWeekIndex + 1}`;

  const selectWeek = (index) => {
    dispatch(setSelectedWeekIndex(index));
  };

  return (
    <div
      className={cn(className, "week-selector", {
        "week-selector--active": showDropdown,
      })}
      onClick={toggleDropdown}
      onMouseLeave={hidding}
      onMouseEnter={stopHiding}
    >
      <div className="week-number">{weekNumberTitle}</div>
      <div className="week-range">
        {selectedWeekIndex >= 0 && weeks && weeks.length
          ? weekRange(weeks[selectedWeekIndex])
          : ""}
      </div>
      <img
        className="week-range-arrow"
        src="/images/caret-down-solid.svg"
        alt=""
      />
      {showDropdown && (
        <div className="week-range-dropdown">
          <Scrollbars style={{ height: scrollBarsHeight }} ref={scrollbars}>
            {weeks
              .map((w, index) => {
                return (
                  <div
                    key={index}
                    className={cn("week-range-dropdown-item", {
                      "week-range-dropdown-item--active":
                        index === selectedWeekIndex,
                    })}
                    onClick={() => selectWeek(index)}
                  >
                    <div className="week-range-dropdown-item__week">
                      Week {index + 1}
                    </div>
                    <div className="week-range-dropdown-item__range">
                      {weekRange(w)}
                    </div>
                  </div>
                );
              })
              .reverse()}
          </Scrollbars>
        </div>
      )}
    </div>
  );
};

export default WeekSelector;
