import React, {useEffect} from "react";
import "./ChannelTable.css";
import {navigate} from "@reach/router";
import {AgGridReact} from "ag-grid-react";
import {fetchStationTable, setStationTable,} from "../product/productSlice";
import {useDispatch, useSelector} from "react-redux";
import format from "number-format.js";
import {NUMBER_FORMAT} from "../utils/constants";
import {dayPartsColorMap, dayPartsTitle} from "../utils/ColorMaps";

const dayparts = [
  { title: "Over Nights", color: "#ff827a", abbreviation: "ON" },
  { title: "Early Mornings", color: "#d165f2", abbreviation: "EM" },
  { title: "Weekend Day", color: "#ffc938", abbreviation: "WD" },
  { title: "Early Fringe", color: "#f973c1", abbreviation: "EF" },
  { title: "Prime Access", color: "#5c99ff", abbreviation: "PA" },
  { title: "Primetime", color: "#5ad3ff", abbreviation: "PR" },
  { title: "Late Fringe", color: "#fba2e5", abbreviation: "LF" },
  { title: "Daytime", color: "#6963ff", abbreviation: "DT" },
];

const buildColumnDefs = (metrics) => {
  const metricsColumns = metrics.map((m) => ({
    headerName: m.name,
    field: m.metricCode,
    valueFormatter: (params) => format(m.format || NUMBER_FORMAT, params.value),
  }));

  return [
    {
      headerName: "Daypart",
      field: "Daypart",
      cellRenderer: "daypartCellRenderer",
      width: 160,
      pinned: "left",
    },
    ...metricsColumns,
  ];
};

const DaypartCellRenderer = ({ value }) => {
  return (
    <div className="daypart__cell">
      <div
        className="daypart__logo"
        style={{ backgroundColor: dayPartsColorMap[value] }}
      >
        {value}
      </div>
      {dayPartsTitle[value]}
    </div>
  );
};

const frameworkComponents = {
  daypartCellRenderer: DaypartCellRenderer,
};

const ChannelTable = ({ station, creative }) => {
  const dispatch = useDispatch();

  const client = useSelector((state) => state.user.client);
  const weeks = useSelector((state) => state.user.weeks);
  const product = useSelector((state) => state.user.product);
  const selectedMarket = useSelector((state) => state.product.selectedMarket);
  const selectedWeekIndex = useSelector(
    (state) => state.user.selectedWeekIndex
  );
  const metrics = useSelector((state) => state.product.tableMetrics);

  const stationTableData = useSelector(
    (state) => state.product.stationTableData
  );

  useEffect(() => {
    const selectedWeek =
      selectedWeekIndex >= 0 ? weeks[selectedWeekIndex] : null;
    if (client && client.clientCode && product && product.productCode) {
      dispatch(
        fetchStationTable(
          client.clientCode,
          product.productCode,
          selectedWeek,
          station,
          creative,
          selectedMarket.marketCode
        )
      );
    } else {
      dispatch(setStationTable({}));
    }
  }, [client, product, weeks, selectedMarket, selectedWeekIndex]);

  const columnDefs = buildColumnDefs(metrics);

  const gridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: true,
      minWidth: 170,
    },
    pagination: true,
    paginationPageSize: 3,
  };

  return (
    <div className="ag-theme-balham channel-table">
      <AgGridReact
        onRowClicked={(row) => {
          navigate(`/table/${station}/${creative}/${row.data.Daypart}`);
        }}
        columnDefs={columnDefs}
        rowData={stationTableData}
        getRowHeight={() => 50}
        suppressCellSelection
        frameworkComponents={frameworkComponents}
        gridOptions={gridOptions}
        immutableData={true}
        getRowNodeId={(data) => data.Daypart}
      />
    </div>
  );
};

export default ChannelTable;
