import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addEnabledWeek,
  delEnabledWeek,
  doSaveEnabledWeeks,
  fetchClientProducts,
  fetchClients,
  fetchMetrics,
  fetchWeeks,
  selectClient,
} from "./adminSlice";

const Weeks = () => {
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.admin.clients);
  const selectedClient = useSelector((state) => state.admin.selectedClient);
  const allWeeks = useSelector((state) => state.admin.allWeeks);
  const enabledWeeks = useSelector((state) => state.admin.enabledWeeks);

  useEffect(() => {
    dispatch(fetchMetrics());
    dispatch(fetchClients());
  }, []);

  useEffect(() => {
    if (selectedClient.clientCode) {
      dispatch(fetchClientProducts(selectedClient.clientCode));
    }
  }, [selectedClient]);

  useEffect(() => {
    if (selectedClient.clientCode) {
      dispatch(fetchWeeks(selectedClient.clientCode));
    }
  }, [selectedClient]);

  const handleClientSelect = (event) => {
    const client = clients.find((c) => c.clientCode === event.target.value);
    dispatch(selectClient(client || {}));
  };

  const handleToogleWeek = (week, isEnabled) => {
    if (isEnabled) {
      dispatch(delEnabledWeek(week));
    } else {
      dispatch(addEnabledWeek(week));
    }
  };

  const handleSave = () => {
    if (selectedClient.clientCode) {
      dispatch(doSaveEnabledWeeks(selectedClient.clientCode, enabledWeeks));
    }
  };

  return (
    <>
      <div className="form-row metrics__selectors-container">
        <div className="form-group admin__form-group form-group--no-grow">
          <label htmlFor="lastName" className="form-label">
            Client
          </label>
          <select
            className="form-control"
            onChange={handleClientSelect}
            value={selectedClient.clientCode}
          >
            {clients.map((c) => (
              <option value={c.clientCode}>{c.name}</option>
            ))}
          </select>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <td>Enabled</td>
            <td>Week</td>
          </tr>
        </thead>
        <tbody>
          {allWeeks.map((aw) => {
            const isEnabled = !!enabledWeeks.find((ew) => ew === aw);

            return (
              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={isEnabled}
                    onChange={() => handleToogleWeek(aw, isEnabled)}
                  />
                </td>
                <td>{aw}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <button className="app-button admin__save-button" onClick={handleSave}>
        Save
      </button>
    </>
  );
};

export default Weeks;
