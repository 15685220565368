import {createSlice} from "@reduxjs/toolkit";
import {getDaypartTable, getExcel, getGrid, getStationTable, getTable} from "../utils/api";
import {get} from "lodash";

const allMarkets = {
  marketCode: "",
  name: "All Markets",
};

const clearedInitialState = {
  grid: {},
  tableMetrics: [],
  tableData: [],
  stationTableData: [],
  daypartTableData: [],
  markets: [allMarkets],
  selectedMarket: allMarkets,
  medias: {},
  selectedMedia: "TV",
  chartMetrics: [],
  selectedMetric: {},
  currentWeek: {},
  previousWeekData: {},
  mainChart: [],
  dayparts: [],
  daypartChart: {},
  spotLengthChart: {},
  stationChart: {},
  creativeChart: {},
  weeksChart: []
};

const productSlice = createSlice({
  name: "product",
  initialState: {
    ...clearedInitialState
  },
  reducers: {
    setGrid(state, action) {
      state.grid = action.payload;
      const markets = get(action.payload, "markets", []);
      state.markets = [allMarkets, ...markets];
      // state.selectedMarket = allMarkets;
      state.medias = get(action.payload, "medias", {});

      const mediaKeys = Object.keys(state.medias)
      if (mediaKeys.length > 0) {
        state.selectedMedia = state.medias[mediaKeys[0]];
      } else {
        state.selectedMedia = 'TV';
      }

      state.chartMetrics = get(action.payload, "metrics", []);
      if (state.chartMetrics.length > 0) {
        state.selectedMetric = state.chartMetrics[0]
      } else {
        state.selectedMetric = {};
      }


      state.currentWeek = get(action.payload, "currentWeek", {});
      state.previousWeek = get(action.payload, "previousWeek", {});
      state.mainChart = get(action.payload, "mainChart", []);

      state.dayparts = get(action.payload, "dayparts", []);
      state.daypartChart = get(action.payload, "daypartChart", {});
      state.spotLengthChart = get(action.payload, "spotLengthChart", {});
      state.stationChart = get(action.payload, "stationChart", {});
      state.creativeChart = get(action.payload, "creativeChart", {});
      state.weeksChart = get(action.payload, "weeksChart", []);
    },
    setTable(state, action) {
      state.tableData = action.payload.data || [];
      state.tableMetrics = action.payload.metrics || [];
    },
    setStationTable(state, action) {
      state.stationTableData = action.payload.data || [];
      state.tableMetrics = action.payload.metrics || [];
    },
    setDaypartTable(state, action) {
      state.daypartTableData = action.payload.data || [];
      state.tableMetrics = action.payload.metrics || [];
    },

    selectMarket(state, action) {
      state.selectedMarket = action.payload;
    },
    selectMetric(state, action) {
      state.selectedMetric = action.payload;
    },
    clearProducts(state, action) {
      state = {...clearedInitialState};
    }
  },
});

export default productSlice.reducer;

export const {setGrid, setTable, setStationTable, setDaypartTable, selectMarket, selectMetric, clearProducts} = productSlice.actions;

export const fetchGrid = (clientCode, productCode, week, marketCode) => {
  return async (dispatch) => {
    try {
      let grid = {};
      if (clientCode && productCode && week) {
        grid = await getGrid(clientCode, productCode, week.week, marketCode);
      }
      dispatch(setGrid(grid));
    } catch (error) {
      console.error("fetchGrid error: ", error);
    }
  };
};

export const fetchTable = (clientCode, productCode, week, marketCode) => {
  return async (dispatch) => {
    try {
      let table = {};
      if (clientCode && productCode && week) {
        table = await getTable(clientCode, productCode, week.week, marketCode);
      }
      dispatch(setTable(table));
    } catch (error) {
      console.error("fetchTable error: ", error);
    }
  };
};

export const fetchStationTable = (clientCode, productCode, week, station, creative, marketCode) => {
  return async (dispatch) => {
    try {
      let stationTable = {};
      if (clientCode && productCode && week && station) {
        stationTable = await getStationTable(clientCode, productCode, week.week, station, creative, marketCode);
      }
      dispatch(setStationTable(stationTable));
    } catch (error) {
      console.error("fetchStationTable error: ", error);
    }
  };
};

export const fetchDaypartTable = (clientCode, productCode, week, station, creative, daypart, marketCode) => {
  return async (dispatch) => {
    try {
      let daypartTable = {};
      if (clientCode && productCode && week && station) {
        daypartTable = await getDaypartTable(clientCode, productCode, week.week, station, creative, daypart, marketCode);
      }
      dispatch(setDaypartTable(daypartTable));
    } catch (error) {
      console.error("fetchDaypartTable error: ", error);
    }
  };
};

export const fetchExcel  = (clientCode, productCode, week, marketCode) => {
  return async (dispatch) => {
    try {
      if (clientCode && productCode && week) {
        await getExcel(clientCode, productCode, week.week, marketCode);
      }
    } catch (error) {
      console.error("fetchDaypartTable error: ", error);
    }
  };
};


