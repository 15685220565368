import React from "react";

import "./AccountSettings.css";
import Switch from "./Switch";

const AccountSettings = () => (
  <div className="account-settings">
    <div className="settings-panel">
      <div className="settings-panel__title">Personal Data</div>
      <div className="settings-panel__description">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor.
      </div>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="firstName" className="form-label">First Name</label>
          <input type="text" className="form-control" placeholder="Enter First Name"/>
        </div>
        <div className="form-group form-row__form-group">
          <label htmlFor="firstName" className="form-label">Last name</label>
          <input type="text" className="form-control" placeholder="Enter Last Name"/>
        </div>
        <div className="form-group form-row__form-group">
          <label htmlFor="firstName" className="form-label">Email</label>
          <input type="text" className="form-control" placeholder="Enter Email"/>
        </div>
      </div>
    </div>
    <div className="settings-panel">
      <div className="settings-panel__title">Password</div>
      <div className="settings-panel__description">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor.
      </div>
      <div className="form-row">
        <div className="form-group ">
          <label htmlFor="firstName" className="form-label">Current Passsord</label>
          <input type="text" className="form-control" placeholder="Enter Current Password"/>
        </div>
        <div className="form-group form-row__form-group">
          <input type="text" className="form-control" placeholder="Enter New Password"/>
        </div>
        <div className="form-group form-row__form-group">
          <input type="text" className="form-control" placeholder=" Confirm Password"/>
        </div>
      </div>
    </div>
    <div className="settings-panel">
      <div className="settings-panel__title">Notifications</div>
      <div className="settings-panel__description">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor.
      </div>
      <div className="notification-settings">
        <Switch title="Email Notification" description="Commit data and history" />
        <Switch title="Push Notification" description="Commit data and history" />
        <Switch title="Monthly Reports" description="Commit data and history" />
      </div>
    </div>
    <div className="account-settings__button-panel">
      <div className="app-button">Save Changes</div>
    </div>
  </div>
);

export default AccountSettings;
