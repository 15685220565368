import React, { useState } from "react";
import "./DashboardChart.css";
import moment from "moment";
import { get } from "lodash";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ChartTab from "./ChartTab";
import { currencyFormatter } from "../utils/formatters";
import {DAYS_OF_WEEK, NUMBER_FORMAT} from "../utils/constants";
import format from "number-format.js";
const colors = ["#FF827A", "#D165F2"];

const getChartOptions = (data, selectedValue) => {

  const series = Object.keys(data).map((k, index) => {
    const values = data[k];
    const valuePerDayOfWeek = {};
    values.forEach((v) => {
      valuePerDayOfWeek[moment(v.date).format("ddd")] = v[selectedValue];
    });

    return {
      name: k,
      color: colors[index % colors.length],
      data: DAYS_OF_WEEK.map((d) => valuePerDayOfWeek[d] || 0),
      marker: { symbol: "circle" },
    };
  });

  return {
    title: {
      text: null,
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: DAYS_OF_WEEK,
    },
    yAxis: {
      title: null,
      labels: {
        format: "${value:,.0f}",
        /*formatter: function () {
          return format(selectedMetric.format || NUMBER_FORMAT, this.value)
        },*/
      },
    },
    legend: {
      enabled: true,
      useHTML: true,
      symbolWidth: 0,
      labelFormatter: function () {
        const checked =
          '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><rect width="20" height="20" fill="' +
          this.color +
          '" rx="5"/><path fill="#FFF" d="M5.707 10.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l7-7a1 1 0 1 0-1.414-1.414L8 12.586l-2.293-2.293z"/></g></svg>';

        const unchecked =
          '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><rect width="20" height="20" fill="' +
          this.color +
          '" rx="5"/><rect width="16" height="16" x="2" y="2" fill="#FFF" rx="3"/></g></svg>';
        return (
          '<div style="display: flex; justify-content: center; align-items: center;"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">\n' +
          (this.visible ? checked : unchecked) +
          '<div style="margin-left: 5px;">' +
          this.name +
          "</div>" +
          "</div>"
        );
      },
    },
    series,
  };
};
const DashboardChart = ({ dashboard, className }) => {
  const [selectedValue, setSelectedValue] = useState("spend");
  const chartOptions = getChartOptions(dashboard.byDate || {}, selectedValue);

  const charts = ["Spend", "Revenue", "CPV"];

  return (
    <div className={`${className} dashboard-chart`}>
      <div className="dashboard-chart__title">
        {charts.map((title, index) => {
          const key = title.toLowerCase();
          const current = get(dashboard, `currentWeek.${key}`, 0);
          const previous = get(dashboard, `previousWeek.${key}`, 0);

          const percent =
            previous === 0
              ? undefined
              : Math.abs(((current - previous) * 100.0) / previous);

          return (
            <ChartTab
              key={index}
              name={title}
              amount={currencyFormatter(current)}
              percent={percent}
              selected={selectedValue === key}
              up={
                current == previous || previous == 0 ? null : current > previous
              }
              handleSelect={() => setSelectedValue(key)}
            />
          );
        })}
      </div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default DashboardChart;
