import React from "react";
import "./ExportReport.css";

const ExportReport = ({onExport}) => (
    <div className="export-report" onClick={onExport}>
        <img className="export-report__icon" src="/images/export.svg" alt="Export" />
        Export Report
    </div>
);

export default ExportReport;
