const LocalStorageService = (function(){
  var _service;
  function _getService() {
    if(!_service) {
      _service = this;
      return _service
    }
    return _service
  }
  function _setToken(tokenObj) {
    sessionStorage.setItem('access_token', tokenObj.accessToken);
    sessionStorage.setItem('refresh_token', tokenObj.refreshToken);
  }
  function _getAccessToken() {
    return sessionStorage.getItem('access_token');
  }
  function _getRefreshToken() {
    return sessionStorage.getItem('refresh_token');
  }
  function _clearTokens() {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
  }
  return {
    getService : _getService,
    setToken : _setToken,
    getAccessToken : _getAccessToken,
    getRefreshToken : _getRefreshToken,
    clearTokens : _clearTokens
  }
})();

export default LocalStorageService;
