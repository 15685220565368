import {createSlice} from "@reduxjs/toolkit";
import {getClient, getClients, getDashboard, getProducts, getEnabledWeeks, login, postRefreshToken} from "../utils/api";
import {navigate} from "@reach/router";
import {get} from "lodash";
import {STATUS_CODE_UNAUTHORIZED} from "../utils/constants";
import LocalStorageService from "../utils/localStorageService";

const localStorageService = LocalStorageService.getService();

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    loginError: null,
    token: null,
    client: {},
    clients: [],
    selectedClientIndex: -1,
    products: [],
    selectedProductIndex: -1,
    product: {},
    weeks: [],
    selectedWeekIndex: -1,
    dashboard: {},
  },
  reducers: {
    loginSuccess(state, action) {
      state.user = action.payload;
      state.loginError = null;
    },
    loginFailed(state, action) {
      state.loginError = action.payload;
    },
    logout(state, action) {
      state.user = null;
      state.client = {};
      state.clients = [];
      state.products = [];
      state.selectedClientIndex = -1;
      state.selectedProductIndex = -1;
      state.weeks = [];
      state.selectedWeekIndex = -1;
    },
    setClients(state, action) {
      state.clients = action.payload;

      if (state.clients.length > 0) {
        state.selectedClientIndex = 0;
        state.client = state.clients[0];
      } else {
        state.selectedClientIndex = -1;
        state.client = {};
      }

      state.products = [];
      state.product = {};
      state.selectedProductIndex = -1;
      state.weeks = [];
      state.selectedWeekIndex = -1;
    },
    setSelectedClientIndex(state, action) {
      state.selectedClientIndex = action.payload;
      if (state.selectedClientIndex >= 0 && state.clients) {
        state.client = state.clients[state.selectedClientIndex];
      } else {
        state.client = {};
      }

      state.products = [];
      state.product = {};
      state.selectedProductIndex = -1;
      state.weeks = [];
      state.selectedWeekIndex = -1;
    },
    setClient(state, action) {
      state.client = action.payload;
    },
    setProducts(state, action) {
      state.products = action.payload;
      if (state.products.length > 0) {
        let selectedProductIndex = 0;

        // if (state.user?.userProducts && state.user?.userProducts.length > 0) {
        //   selectedProductIndex = state.products.findIndex(p => state.user?.userProducts.includes(p));
        // }
        //
        // console.log('selectedProductIndex: ', selectedProductIndex);

        state.selectedProductIndex = selectedProductIndex;
        state.product = state.products[state.selectedProductIndex];
      } else {
        state.selectedWeekIndex = -1;
        state.product = {};
      }
    },
    setSelectedProductIndex(state, action) {
      console.log('setSelectedProductIndex');

      state.selectedProductIndex = action.payload;
      if (state.selectedProductIndex >= 0 && state.products && state.selectedProductIndex < state.products.length) {
        state.product = state.products[state.selectedProductIndex];
      } else {
        state.product = {};
      }
    },
    setWeeks(state, action) {
      state.weeks = action.payload;

      if (state.weeks.length > 0) {
        state.selectedWeekIndex = state.weeks.length - 1;
      } else {
        state.selectedWeekIndex = -1;
      }
    },
    setSelectedWeekIndex(state, action) {
      state.selectedWeekIndex = action.payload;
    },
    setDashboard(state, action) {
      state.dashboard = action.payload;
    },
  },
});

export default userSlice.reducer;

export const {
  loginSuccess,
  loginFailed,
  logout,
  setClients,
  setClient,
  setSelectedClientIndex,
  setProducts,
  setSelectedProductIndex,
  setWeeks,
  setSelectedWeekIndex,
  setDashboard,
} = userSlice.actions;

export const doCheckAuthentication = () => {
  return async (dispatch) => {
    try {
      const refreshToken = localStorageService.getRefreshToken();

      if (refreshToken) {
        const loginData = await postRefreshToken(refreshToken);

        dispatch(loginSuccess(loginData));
        localStorageService.setToken(loginData);
      }
    } catch (error) {
      console.error("doLogin error: ", error);

      const status = get(error, 'response.status');

      if (status === STATUS_CODE_UNAUTHORIZED) {
        await dispatch(doLogout());
      }
    }
  };
};

export const doLogin = (email, password) => {
  return async (dispatch) => {
    try {
      const loginData = await login(email, password);

      dispatch(loginSuccess(loginData));
      localStorageService.setToken(loginData);
    } catch (error) {
      console.error("doLogin error: ", error);
      dispatch(loginFailed("Login error: " + error.message))
    }
  };
};

export const doLogout = () => {
  return async (dispatch) => {
    try {
      localStorageService.clearTokens();
      dispatch(logout());
      navigate('/');
    } catch (error) {
      console.error("doLogout error: ", error);
    }
  };
};

export const doChangeClient = (clientCode) => {
  return async (dispatch) => {
    try {
      const weeks = await getEnabledWeeks(clientCode);
      dispatch(setWeeks(weeks));

      const products = await getProducts(clientCode);
      dispatch(setProducts(products));
    } catch (error) {
      console.error("fetchWeeks error: ", error);
    }
  };
}


export const fetchClients = () => {
  return async (dispatch) => {
    try {
      const clients = await getClients();

      dispatch(setClients(clients));
    } catch (error) {
      console.error("fetchClients error: ", error);
    }
  };
};

export const fetchClient = (clientId) => {
  return async (dispatch) => {
    try {
      const client = await getClient(clientId);

      dispatch(setClient(client));
    } catch (error) {
      console.error("fetchClient error: ", error);
    }
  };
};

export const fetchDashboard = (clientId, week) => {
  return async (dispatch) => {
    try {
      let dashboard = {};
      if (week) {
        dashboard = await getDashboard(clientId, week.week);
      }
      dispatch(setDashboard(dashboard));
    } catch (error) {
      console.error("fetchDashboard error: ", error);
    }
  };
};
