import React from "react";
import "./Header.css";
import WeekSelector from "./WeekSelector";
import {connect, useDispatch, useSelector} from "react-redux";
import {Link} from "@reach/router";
import Dropdown from "./Dropdown";
import {findIndex, get} from "lodash";
import {doLogout, setSelectedClientIndex} from "../user/userSlice";
import {clearProducts} from "../product/productSlice";

const Header = ({
                  clients,
                  selectedClientIndex,
                  weeks,
                  selectedWeekIndex,
                  showNotifications,
                }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);
  const role = useSelector((state) => get(state, "user.user.role", ""));
  const handleClientSelect = (selectedClient) => {
    const selectedIndex = findIndex(clients, (c) => c === selectedClient);
    dispatch(setSelectedClientIndex(selectedIndex));
  };

  const handleLogout = () => {
    dispatch(doLogout());
    dispatch(clearProducts());
  };

  return (
    <div className="header">
      {/*<Link to="/">
        <img src="/images/chief-media-logo-svg-white-final.svg" alt="Logo"/>
      </Link>*/}
      {
        clients.length > 1 &&
        (<Dropdown
          options={clients}
          selectedOption={
            clients.length ? clients[selectedClientIndex] : undefined
          }
          className="client-selector"
          renderOption={(c) => c.name}
          handleSelect={handleClientSelect}
        />)
      }
      <WeekSelector
        className="header__week-selector"
        weeks={weeks}
        selectedWeekIndex={selectedWeekIndex}
      />
      {/*<img*/}
      {/*  className="notifications-toggle"*/}
      {/*  src="/images/notification.svg"*/}
      {/*  alt="Notifications"*/}
      {/*  onClick={showNotifications}*/}
      {/*/>*/}
      {/*<Link to="/account-settings">*/}
      {/*  <img className="profile" src="/images/profile.svg" alt="Profile" />*/}
      {/*</Link>*/}
      {role === "Administrator" && (
        <Link to="/admin" className="header__admin">
          A
        </Link>
      )}
      <img
        className="header__logout"
        src="/images/logout.svg"
        alt="Logout"
        onClick={handleLogout}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const {clients, selectedClientIndex, weeks, selectedWeekIndex} = state.user;
  return {
    clients,
    selectedClientIndex,
    weeks,
    selectedWeekIndex,
  };
};

export default connect(mapStateToProps, null)(Header);
