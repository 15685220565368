import format from "number-format.js";
import {CURRENCY_FORMAT, NUMBER_FORMAT} from "./constants";

export function numberFormatter(value) {
  return format(NUMBER_FORMAT, value);
}

export function currencyFormatter(value) {
  return format(CURRENCY_FORMAT, value);
}

export function percentageFormatter(value) {
  return format("#,##0.0%", value);
}

export function tableCurrencyFormatter(params) {
  return currencyFormatter(params.value);
}
