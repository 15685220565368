import React from "react";

import "./DashboardTitle.css";

const DashboardTitle = ({ clientName }) => (
  <div className="dashboard-title">
    {/*<div className="dashboard-title__logo"></div>*/}
    <div className="dashboard-title__container">
      <div className="dashboard-title__client-name">{clientName}</div>
      <div className="dashboard-title__page-name">Dashboard</div>
    </div>
  </div>
);

export default DashboardTitle;
