import React, {useEffect, useRef} from "react";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import "./Notifications.css";
import Message from "./Message";

const Notifications = ({hideNotifications}) => {
  const listRef = useRef();

  useEffect(() => {
    if (listRef.current) {
      disableBodyScroll(listRef.current);
    }
    return () => {
      enableBodyScroll(listRef.current);
    }
  }, [listRef]);

  return (
    <div className="notifications__background">
      <div className="notifications">
        <div className="notifications__header">
          <img src="/images/notification.svg" alt="Notifications"/>
          <span className="notifications__title">Notifications</span>
          <img
            src="/images/chevron-right-solid--white.svg"
            alt="Notifications"
            className="notifications__hide"
            onClick={hideNotifications}
          />
        </div>
        <div className="notifications__list" ref={listRef}>
          {Array(10)
          .fill(0)
          .map(() => (
            <Message/>
          ))}
        </div>
      </div>
    </div>
  )
};

export default Notifications;
