import axios from "axios";
import {ROOT_URL, STATUS_CODE_OK} from "./constants";

import registerInterceptors from './axiosInterceptors';
registerInterceptors();

export const REFRESH_TOKEN_URL = `${ROOT_URL}/Auth/RefreshToken`;

const AUTH_URL = `${ROOT_URL}/Auth`;
const CLIENTS_URL = `${ROOT_URL}/Clients`;
const CLIENT_URL = (clientId) => `${ROOT_URL}/Clients/${clientId}`;
const CLIENT_ENABLED_WEEKS_URL = (clientId) => `${ROOT_URL}/Clients/${clientId}/EnabledWeeks`;
const CLIENT_WEEKS_URL = (clientCode, productCode) => `${ROOT_URL}/Clients/${clientCode}/Weeks`;

const PRODUCTS_URL = (clientCode) =>
  `${ROOT_URL}/Clients/${clientCode}/Products`;
const DASHBOARD_URL = (clientId, week) =>
  `${ROOT_URL}/Clients/${clientId}/Dashboard?week=${week}`;
const GRID_URL = (clientCode, productCode, week) =>
  `${ROOT_URL}/Clients/${clientCode}/Products/${productCode}/Grid?week=${week}`;
const GRID_BY_MARKET_URL = (clientCode, productCode, week, marketCode) =>
  `${GRID_URL(clientCode, productCode, week)}&marketCode=${marketCode}`;

const TABLE_URL = (clientCode, productCode, week, marketCode) =>
  `${ROOT_URL}/Clients/${clientCode}/Products/${productCode}/Table?week=${week}${
    marketCode ? "&marketCode=" + marketCode : ""
  }`;

const STATION_TABLE_URL = (
  clientCode,
  productCode,
  week,
  station,
  creative,
  marketCode
) =>
  `${ROOT_URL}/Clients/${clientCode}/Products/${productCode}/StationTable?week=${week}&station=${station}${
    creative ? "&creative=" + creative : ""
  }${marketCode ? "&marketCode=" + marketCode : ""}`;

const DAYPART_TABLE_URL = (
  clientCode,
  productCode,
  week,
  station,
  creative,
  daypart,
  marketCode
) =>
  `${ROOT_URL}/Clients/${clientCode}/Products/${productCode}/DaypartsTable?week=${week}&station=${station}${
    creative ? "&creative=" + creative : ""
  }${daypart ? "&daypart=" + daypart : ""}${
    marketCode ? "&marketCode=" + marketCode : ""
  }`;

const EXCEL_URL = (
    clientCode,
    productCode,
    week,
    marketCode
) =>
    `${ROOT_URL}/Clients/${clientCode}/Products/${productCode}/Excel?week=${week}${marketCode ? "&marketCode=" + marketCode : ""}`;

const ACCOUNTS_URL = `${ROOT_URL}/Accounts`;
const USERS_URL = `${ROOT_URL}/Users`;
const USER_URL = (userId) => `${ROOT_URL}/Users/${userId}`;

const METRICS_URL = `${ROOT_URL}/Metrics`;

const CLIENT_PRODUCT_METRICS_URL = (clientCode, productCode) => `${ROOT_URL}/Clients/${clientCode}/Products/${productCode}/Metrics`;

export function login(email, password) {
  const data = {
    email,
    password,
  };

  return axios
    .post(AUTH_URL, data)
    .then((response) => response.data);
}

export function postRefreshToken(refreshToken) {
  return axios.post(REFRESH_TOKEN_URL,
    {
      refreshToken
    })
    .then((response) => response.data);

}


export function getProducts(clientCode) {
  return axios
    .get(PRODUCTS_URL(clientCode))
    .then((response) => response.data);
}

export function getEnabledWeeks(clientId) {
  return axios
    .get(CLIENT_ENABLED_WEEKS_URL(clientId))
    .then((response) => response.data);
}

export function getClients() {
  return axios
    .get(CLIENTS_URL)
    .then((response) => response.data);
}

export function getClient(clientId) {
  return axios
    .get(CLIENT_URL(clientId))
    .then((response) => response.data);
}

export function getDashboard(clientId, week) {
  return axios
    .get(DASHBOARD_URL(clientId, week))
    .then((response) => response.data);
}

export function getGrid(clientCode, productCode, week, marketCode = "") {
  const url = marketCode
    ? GRID_BY_MARKET_URL(clientCode, productCode, week, marketCode)
    : GRID_URL(clientCode, productCode, week);

  return axios
    .get(url)
    .then((response) => response.data);
}

export function getTable(clientCode, productCode, week, marketCode = "") {
  const url = TABLE_URL(clientCode, productCode, week, marketCode);

  return axios
    .get(url)
    .then((response) => response.data);
}

export function getStationTable(
  clientCode,
  productCode,
  week,
  station,
  creative = "",
  marketCode = ""
) {
  const url = STATION_TABLE_URL(
    clientCode,
    productCode,
    week,
    station,
    creative,
    marketCode
  );

  return axios
    .get(url)
    .then((response) => response.data);
}

export function getDaypartTable(
  clientCode,
  productCode,
  week,
  station,
  creative = "",
  daypart,
  marketCode = ""
) {
  const url = DAYPART_TABLE_URL(
    clientCode,
    productCode,
    week,
    station,
    creative,
    daypart,
    marketCode
  );

  return axios
    .get(url)
    .then((response) => response.data);
}

export function getAccounts() {
  return axios
    .get(ACCOUNTS_URL)
    .then((response) => response.data);
}

export function getUsers() {
  return axios
    .get(USERS_URL)
    .then((response) => response.data);
}

export function getUserById(userId) {
  const url = USER_URL(userId);

  return axios
    .get(url)
    .then((response) => response.data);
}

export function createUser(user) {
  return axios
    .post(USERS_URL, user)
    .then((response) => response.data.code === STATUS_CODE_OK);
}

export function updateUser(user) {
  const url = USER_URL(user.id);

  return axios
    .put(url, user)
    .then((response) => response.data.code === STATUS_CODE_OK);
}

export function deleteUser(userId) {
  const url = USER_URL(userId);

  return axios
    .delete(url)
    .then((response) => response.data.code === STATUS_CODE_OK);
}

export function getMetrics() {
  return axios
    .get(METRICS_URL)
    .then((response) => response.data);
}

export const saveProductMetrics = (clientCode, product) => {
  const url = CLIENT_PRODUCT_METRICS_URL(clientCode, product.productCode);

  console.log('product:', product);
  const tableMetrics = product.tableMetrics.reduce((acc, curr) => {
    acc[curr.metricCode] = curr.order;
    return acc;
  }, {});

  const data = {
    chartMetrics: product.chartMetrics.map((m) => m.metricCode),
    tableMetrics,
  };

  console.log('data: ', data);

  return axios
    .post(url, data)
    .then((response) => response.data.code === STATUS_CODE_OK);
};

export const saveEnabledWeeks = (clientCode, enabledWeeks) => {
  const url = CLIENT_WEEKS_URL(clientCode);

  const data = {
    enabled: enabledWeeks,
  };
  return axios
    .post(url, data)
    .then((response) => response.data.code === STATUS_CODE_OK);
};

export function getWeeks(clientCode) {
  const url = CLIENT_WEEKS_URL(clientCode);
  return axios
    .get(url)
    .then((response) => response.data);
}

export function getExcel(
    clientCode,
    productCode,
    week,
    marketCode = ""
) {
  const url = EXCEL_URL(
      clientCode,
      productCode,
      week,
      marketCode
  );

  return axios
      .get(url, {
        responseType: 'blob',
        timeout: 30000,
      })
      .then((response) => response.data)
      .then((data) => {

        // 2. Create blob link to download
        var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `export_${(new Date()).getTime()}.xlsx`);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
      })

}
