import React from "react";
import cn from "classnames";
import "./ChartTab.css";
import { currencyFormatter, percentageFormatter } from "../utils/formatters";

const ChartTab = ({
  name,
  amount,
  percent,
  up = null,
  selected = false,
  handleSelect,
}) => (
  <div
    className={cn("chart-tab", { "chart-tab--selected": selected })}
    onClick={() => handleSelect(name)}
  >
    <div className="chart-tab__name">{name}</div>
    <div className="chart-tab__amount">{amount}</div>
    <div className="chart-tab__diff">
      <div className="chart-tab__arrow">
        {up != null && (<img src={`/images/metric-${up ? "up" : "down"}.svg`}  alt="UpDown"/>)}
      </div>
      <div className="chart-tab__persent">{percentageFormatter(percent)}</div>
    </div>
  </div>
);

export default ChartTab;
