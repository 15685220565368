import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import "./Admin.css";

const UserForm = ({ isCreate, user, clients, accounts, submitHandler }) => {
  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      password: "",
      role: user.role || "User",
      accountId: (user.account || {}).accountId,
      clientCode: (user.client || {}).clientCode,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Required field"),
      lastName: Yup.string().required("Required field"),
      email: Yup.string().required("Required field"),
      role: Yup.string().required("Required field"),
      accountId: Yup.string().when("role", {
        is: (r) => r === "User" || r === "Executive",
        then: Yup.string().required("Required field"),
      }),
      clientCode: Yup.string().when("role", {
        is: (r) => r === "User",
        then: Yup.string().required("Required field"),
      }),
      password: isCreate
        ? Yup.string().required("Required field")
        : Yup.string(),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const {
        accountId,
        clientCode,
        firstName,
        lastName,
        role,
        email,
        password,
      } = values;

      submitHandler({
        accountId,
        clientCode,
        firstName,
        lastName,
        role,
        email,
        password,
      });

      formik.setSubmitting(false);
    },
    onError: async (v) => {
      console.log(v);
    },
  });

  // const handleChangeClient = (e, field, values, setValues) => {
  //   formik.setValues({values})
  //
  //   formik.getFieldProps("firstName")
  // }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-group">
        <label htmlFor="firstName" className="form-label">
          First Name
        </label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter First Name"
          {...formik.getFieldProps("firstName")}
        />
        {formik.touched.firstName && formik.errors.firstName && (
          <div className="form-error">{formik.errors.firstName}</div>
        )}
      </div>
      <div className="form-group admin__form-group">
        <label htmlFor="lastName" className="form-label">
          Last name
        </label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Last Name"
          name="lastName"
          {...formik.getFieldProps("lastName")}
        />
        {formik.touched.lastName && formik.errors.lastName && (
          <div className="form-error">{formik.errors.lastName}</div>
        )}
      </div>
      <div className="form-group admin__form-group">
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Email"
          name="email"
          {...formik.getFieldProps("email")}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="form-error">{formik.errors.email}</div>
        )}
      </div>

      <div className="form-group admin__form-group">
        <label htmlFor="role" className="form-label">
          Role
        </label>
        <select
          className="form-control"
          name="role"
          {...formik.getFieldProps("role")}
        >
          <option>Administrator</option>
          <option>Executive</option>
          <option>User</option>
        </select>
        {formik.touched.role && formik.errors.role && (
          <div className="form-error">{formik.errors.role}</div>
        )}
      </div>
      <div className="form-group admin__form-group">
        <label htmlFor="accountId" className="form-label">
          Account
        </label>
        <select
          className="form-control"
          name="accountId"
          {...formik.getFieldProps("accountId")}
        >
          <option value="">Select an Account</option>
          {accounts.map((a) => (
            <option value={a.accountId}>{a.name}</option>
          ))}
        </select>
        {formik.touched.accountId && formik.errors.accountId && (
          <div className="form-error">{formik.errors.accountId}</div>
        )}
      </div>

      <div className="form-group admin__form-group">
        <label htmlFor="role" className="form-label">
          Client
        </label>
        <select
          className="form-control"
          name="clientCode"
          {...formik.getFieldProps("clientCode")}
        >
          <option value="">Select a Client</option>
          {clients.map((c) => (
            <option value={c.clientCode}>{c.name}</option>
          ))}
        </select>
        {formik.touched.clientCode && formik.errors.clientCode && (
          <div className="form-error">{formik.errors.clientCode}</div>
        )}
      </div>
      <div className="form-group admin__form-group">
        <label htmlFor="password" className="form-label">
          Password
        </label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Password"
          name="password"
          {...formik.getFieldProps("password")}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="form-error">{formik.errors.password}</div>
        )}
      </div>
      <button
        className="app-button admin__save-button"
        disabled={formik.isSubmitting}
        type="submit"
      >
        {isCreate ? "Save" : "Save Changes"}
      </button>
    </form>
  );
};

export default UserForm;
