import React, {useEffect, useState} from "react";
import "./Breadcrumbs.css";
import {Link, navigate} from "@reach/router";
import {connect, useDispatch, useSelector} from "react-redux";
import {setSelectedProductIndex} from "../user/userSlice";

const Breadcrumbs = ({
  client,
  product,
  station,
  creative,
  isTable = true,
}) => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.user.products);
  const user = useSelector((state) => state.user.user);

  const selectedProductIndex = useSelector((state) => state.user.selectedProductIndex);

  let allowedProducts = [];

  if (products.length > 1 && user?.userProducts  && user?.userProducts.length > 0) {
    allowedProducts = products.filter(p => !!user.userProducts.find(up => up === p.productCode));
  }

  const handleSelectProduct = (event) => {
    const selectProduct = allowedProducts[event.target.value];

    const selectProductIndex = products.findIndex(p => p.productCode === selectProduct.productCode);

    dispatch(setSelectedProductIndex(selectProductIndex));
  };

  return (
    <div className="breadcrumbs">
      <div onClick={() => navigate("/")} className="breadcrumbs__item">
        {client.name}
      </div>
      <div className="delimiter">></div>
      {allowedProducts.length > 1 ? (
        <select onChange={handleSelectProduct} value={selectedProductIndex}>
          {allowedProducts.map((p, i) => {
              return (
                  <option key={i} value={i}>{p.name}</option>
              )
          })}
        </select>
      ) : (
        <Link to={isTable ? "/table" : "/"} className="breadcrumbs__item">
          {product.name}
        </Link>
      )}

      {station && (
        <>
          <div className="delimiter">></div>
          <div className="breadcrumbs__item">{station}</div>
        </>
      )}
      {creative && (
        <>
          <div className="delimiter">></div>
          <div className="breadcrumbs__item">{creative}</div>
        </>
      )}
      {/*<div className="breadcrumbs__item">Account Settings</div>*/}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    client: state.user.client,
    product: state.user.product,
  };
};

export default connect(mapStateToProps, null)(Breadcrumbs);
