import axios from "axios";
import {get} from "lodash";
import {navigate} from "@reach/router";
import LocalStorageService from "./localStorageService";
import {REFRESH_TOKEN_URL} from "./api";
import {NotificationManager} from 'react-notifications';
const localStorageService = LocalStorageService.getService();

function registerInterceptors() {
// Add a request interceptor
  axios.interceptors.request.use(
    config => {
      const token = localStorageService.getAccessToken();
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
      return config;
    },
    error => {
      Promise.reject(error)
    });


//Add a response interceptor
  var refreshing = false;

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    function (error) {
      const originalRequest = error.config;

      // if refresh token fails
      const status = get(error, 'response.status');
      if (status === 401) {
        if (originalRequest.url === REFRESH_TOKEN_URL) {
          navigate('/');

          return Promise.reject(error);
        }

        if (!originalRequest._retry && !refreshing) {
          refreshing = true;
          originalRequest._retry = true;
          const refreshToken = localStorageService.getRefreshToken();

          return axios.post(REFRESH_TOKEN_URL,
            {
              "refreshToken": refreshToken
            })
            .then(res => {
              // store refresh token and use new access token for the postponed call
              if (res.status === 201 || res.status === 200) {
                localStorageService.setToken(res.data);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();
                return axios(originalRequest);
              }
            })
            .finally(() => {
              refreshing = false;
            })
        }
      }

      NotificationManager.error(error.message, 'API Error', 5000);

      return Promise.reject(error);
    });
}

export default registerInterceptors;

