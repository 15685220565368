import React, { useEffect } from "react";
import ProductChart from "./ProductChart";
import ChartPanel from "./ChartPanel";
import DaypartsChart from "./DaypartsChart";
import StationChart from "./StationChart";
import CreativeChart from "./CreativeChart";
import SpotLengthChart from "./SpotLengthChart";
import { useDispatch, useSelector } from "react-redux";
import { fetchGrid, setGrid } from "../product/productSlice";
import WeeksChart from "./WeeksChart";

const ProductGrid = () => {
  const client = useSelector((state) => state.user.client);
  const weeks = useSelector((state) => state.user.weeks);
  const product = useSelector((state) => state.user.product);
  const selectedMarket = useSelector((state) => state.product.selectedMarket);
  const selectedWeekIndex = useSelector(
    (state) => state.user.selectedWeekIndex
  );

  const dispatch = useDispatch();
  useEffect(() => {
    const selectedWeek =
      selectedWeekIndex >= 0 ? weeks[selectedWeekIndex] : null;
    if (client && client.clientCode && product && product.productCode) {
      dispatch(fetchGrid(client.clientCode, product.productCode, selectedWeek, selectedMarket.marketCode));
    } else {
      dispatch(setGrid({}));
    }
  }, [client, product, weeks, selectedMarket, selectedWeekIndex]);

  return (
    <>
      <ProductChart classes="app__product_chart" />
      <div className="chart-row">
        <div className="chart-column">
          <ChartPanel title="Dayparts" chart={<DaypartsChart />} />
        </div>
        <div className="chart-column">
          <ChartPanel title="Spot Length" chart={<SpotLengthChart />} />
        </div>
      </div>
      <div className="chart-row">
        <div className="chart-column">
          <ChartPanel title="Station" chart={<StationChart />} />
        </div>
      </div>
      <div className="chart-row">
        <div className="chart-column">
          <ChartPanel title="Creative" chart={<CreativeChart />} />
        </div>
        <div className="chart-column" >
          <ChartPanel title="Weeks" chart={<WeeksChart />} />
        </div>
      </div>
    </>
  );
};

export default ProductGrid;
